<template>
  <div class="page-box section-wrap">
    <div class="about-banner section section-1">
      <!-- <img class="about-us" src="~@/assets/img/about-us.png" alt="" /> -->
      <div class="about-box">
        <div class="about-en">ABOUT US</div>
        <div class="line"></div>
        <div class="about-ch">关于我们</div>
      </div>
      <!-- <div class="bg1"></div> -->
      <img class="about-bg" src="~@/assets/img/about-bg.png" alt="" />
    </div>
    <div id="kinds" class="about-bg section section-2">
      <div class="about-text">
        <div class="left">
          <div class="title1" v-if="lang==1"><span>关于</span><span>我们</span></div>
          <div class="title1" v-if="lang==2"><span>ABOUT</span><span>US</span></div>
          <div class="title2">{{info.title}}</div>
          <div class="title3">
            {{info.content}}
          </div>
          <div class="team">
            <div class="list">
              <div class="num"><CountTo :startVal='startVal' :endVal='Number(info.team)' :duration='duration' />
                <span class="unit">{{lang==1?'年':'Y'}}</span></div>
              <div class="text">{{lang==1?'经验团队':'Experience team'}}</div>
            </div>
            <div class="list">
              <div class="num"><CountTo :startVal='startVal' :endVal='Number(info.base)' :duration='duration' />
                <span class="unit">M</span></div>
              <div class="text">{{lang==1?'生产基地':'Production Base'}}</div>
            </div>
            <div class="list">
              <div class="num"><CountTo :startVal='startVal' :endVal='Number(info.engineer)' :duration='duration' />
                <span class="unit">{{lang==1?'位':'P'}}</span></div>
              <div class="text">{{lang==1?'技术工程师':'Technical Engineer'}}</div>
            </div>
            <div class="list">
              <div class="num"><CountTo :startVal='startVal' :endVal='Number(info.outlets)' :duration='duration' />
                <span class="unit">+</span></div>
              <div class="text">{{lang==1?'技术工程师':'Sales Outlets'}}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="cir cir1">
            <div class="cir cir2"><img class="r-img" src="~@/assets/img/wanju.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-3">
      <FormInput></FormInput>
    </div>
    <ul class="section-btn">
			<li class="on"></li>
			<li></li>
			<li></li>
		</ul>
  </div>
</template>

<script>
import FormInput from '../../components/FormInput.vue'
import { getAction } from "@/api/manage";
import CountTo from 'vue-count-to'
export default {
  components: { FormInput,CountTo },
  name: "index",
  data() {
    return {
      info: {},
      startVal: 0,
      duration: 4000,
      timer: null,
      lang: 1
    }
  },
  mounted() {
    this.lang = localStorage.getItem('lang')
    this.tcompanyProfile() //公司介绍
  },
  methods: {
   
    tcompanyProfile() {
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tcompanyProfile/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang')
        }
      ).then((res) => {
        this.info = res.data.list[0];
      });
    }
  },
}
</script>

<style lang="scss" scoped>

.about-banner{
  position: relative;
  .bg1{
    width: 100%;
    height: 100%;
    background: url("~@/assets/img/pro3.png") no-repeat 50%;
  }
  .about-bg{
    width: 100%;
  }
  .about-us{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -250px;
  }
  .about-box{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -250px;
    color: #cdba93;
    text-align: center;
    .about-en{
      font-size: 92px;
      font-weight: bold;
      margin-top: -10px;
      opacity: 0;
      transform: translateX(-500px);
      /* 动画名称和持续时间 */
      animation: flyIn 1s forwards;
    }
    .line{
      height: 10px;
      width: 197px;
      background: #cdba93;
      margin: 0 auto;
      border-radius: 10px;
      position: relative;
      left: 10px;
      top: 15px;
    }
    .about-ch{
      font-size: 61px;
      margin-top: 40px;
      margin-left: 14px;
      opacity: 0;
      transform: translateX(500px);
      /* 动画名称和持续时间 */
      animation: flyIn2 1s forwards;
    }
    /* 定义动画 */
    @keyframes flyIn {
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @keyframes flyIn2 {
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
.about-bg{
  background: url("~@/assets/img/about-bg.png");
  background-size: 100% 100%;
  .about-text{
    width: 1400px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    .left{
      flex: 1;
      .title1{
        font-size: 46px;
        font-weight: bold;
        span:nth-child(1){
          color: #000;
        }
        span:nth-child(2){
          color: #53bfc6;
        }
      }
      .title2{
        color: #606060;
        font-size: 22px;
        margin: 9px 0;
      }
      .title3{
        color: #606060;
        font-size: 14px;
        line-height: 40px;
        text-align: justify;
      }
      .team{
        display: flex;
        .list{
          flex: 1;
        }
        .num{
          font-size: 28px;
          font-weight: bold;
          font-family: math;
        }
        .unit{
          display: inline-block;
          background: #CDBA93;
          color: #fff;
          text-align: center;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
        }
      }
    }
    .right{
      flex: 1;
      padding-left: 20px;
      .cir{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cir1{
         width: 580px;
         height: 580px;
         text-align: center;
         background: #fff;
          // background: #000;
      }
      .cir2{
         width: 530px;
         height: 530px;
         background: #f2f2f2;
      }
      .r-img{
        width: 500px;
        border-radius: 50%;
      }
    }
  }
}


html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.section-wrap {
  width: 100%;
  height: 80%;
  overflow: visible;
  transition: transform 1s cubic-bezier(0.86, 0, 0.03, 1);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.03, 1);
}

.section-wrap .section {
  position: relative;
  width: 100%;
  // height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
}

.section-wrap .section-1 {
  height: 731px;
}

.section-wrap .section-2 {
  height: 680px;
}

.section-wrap .section-3 {
  background-color: #5bc0de;
  height: 895px;
}

.put-section-0 {
  transform: translateY(0);
}

// .put-section-1 {
//   transform: translateY(-183px);
// }

// .put-section-2 {
//   transform: translateY(-914px);
// }

// .put-section-3 {
//   transform: translateY(-900px);
// }



@keyframes bg1anmi {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(-916px)
	}
}

@keyframes bg2anmi {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(-916px)
	}
}

.banner {
	height: 650px;
	// border-bottom: 1px solid #222223;
	position: relative;
	overflow: hidden;
	perspective: 500px;
	transform-style: preserve-3d
}

.banner .bg {
	min-width: 2200px;
	height: 1432px;
	z-index: 0;
	position: absolute;
	left: -22%;
	right: -22%;
	top: -22%;
	bottom: -22%;
	transform: rotateX(15deg);
	transform-origin: center;
	vertical-align: middle
}

.banner .bg1 {
	// height: 916px;
	background: 50% no-repeat;
	background-size: 100% 100%;
	animation: bg1anmi 30s linear infinite;
}

.banner .bg2 {
	// height: 916px;
	background: 50% no-repeat;
	background-size: 100% 100%;
	animation: bg2anmi 30s linear infinite;
  // opacity: .5;
}

// .banner:before {
// 	content: "";
// 	position: absolute;
// 	right: 0;
// 	top: -2%;
// 	bottom: 0;
// 	left: 0;
// 	background-size: 105% 105%;
// 	z-index: 1;
// 	background-color: #000;
//     opacity: .7;
// }

// .home .banner:after {
// 	content: "";
// 	position: absolute;
// 	right: 4%;
// 	top: 38%;
// 	bottom: 0;
// 	width: 1266px;
// 	height: 337px;
// 	background: url(../img/background.png) no-repeat 50%;
// 	z-index: 1
// }
</style>
